<div class="w-full xl:w-fit inline-flex flex-col gap-2 border rounded-md border-input bg-background p-4">
  <span class="flex items-center pb-2 gap-2 text-muted-foreground">
    <lucide-angular [img]="ListFilter" class="size-4" />
    <h3 class="text-base font-semibold tracking-wide">Filter</h3>
  </span>
  <form ngForm class="flex flex-wrap gap-4">
    <app-leaderboard-filter-timeframe />
    @if (teams() && teams()!.length > 1) {
      <app-leaderboard-filter-team [teams]="teams()!" />
    }
  </form>
</div>
