import { Directive, computed, inject } from '@angular/core';
import { BrnAvatarFallbackDirective, hexColorFor, isBright } from '@spartan-ng/ui-avatar-brain';
import { hlm } from '@spartan-ng/ui-core';

@Directive({
  selector: '[hlmAvatarFallback]',
  standalone: true,
  exportAs: 'avatarFallback',
  hostDirectives: [
    {
      directive: BrnAvatarFallbackDirective,
      inputs: ['class:class', 'autoColor:autoColor']
    }
  ],
  host: {
    '[class]': '_computedClass()',
    '[style.backgroundColor]': "_hex() || ''"
  }
})
export class HlmAvatarFallbackDirective {
  private readonly _brn = inject(BrnAvatarFallbackDirective);
  private readonly _hex = computed(() => {
    if (!this._brn.useAutoColor() || !this._brn.getTextContent()) return;
    return hexColorFor(this._brn.getTextContent());
  });

  private readonly _autoColorTextCls = computed(() => {
    const hex = this._hex();
    if (!hex) return;
    return `${isBright(hex) ? 'text-black' : 'text-white'}`;
  });

  protected readonly _computedClass = computed(() => {
    return hlm('flex h-full w-full items-center justify-center rounded-full', this._autoColorTextCls() ?? 'bg-muted', this._brn?.userCls());
  });
}
