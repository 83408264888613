import { Component, input, output } from '@angular/core';
import { LucideAngularModule, Plus, BotMessageSquare } from 'lucide-angular';
import { HlmButtonModule } from '@spartan-ng/ui-button-helm';
import { HlmSkeletonComponent } from '@spartan-ng/ui-skeleton-helm';

@Component({
  selector: 'app-start-session-card',
  standalone: true,
  templateUrl: './start-session-card.component.html',
  imports: [LucideAngularModule, HlmButtonModule, HlmSkeletonComponent]
})
export class StartSessionCardComponent {
  protected Plus = Plus;
  protected BotMessageSquare = BotMessageSquare;

  createNewSession = output<void>();
  isLoading = input<boolean>();
  hasSessions = input<boolean>(false);
}
