import { ChangeDetectionStrategy, Component, ViewEncapsulation, computed, effect, input } from '@angular/core';
import { BrnAccordionContentComponent } from '@spartan-ng/ui-accordion-brain';
import { hlm } from '@spartan-ng/ui-core';
import type { ClassValue } from 'clsx';

@Component({
  selector: 'hlm-accordion-content',
  template: `
    <div [attr.inert]="_addInert()" style="overflow: hidden">
      <p [class]="_contentClass()">
        <ng-content />
      </p>
    </div>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class]': '_computedClass()'
  }
})
export class HlmAccordionContentComponent extends BrnAccordionContentComponent {
  public readonly userClass = input<ClassValue>('', { alias: 'class' });
  protected readonly _computedClass = computed(() => {
    const gridRows = this.state() === 'open' ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]';
    return hlm('text-sm transition-all grid', gridRows, this.userClass());
  });

  constructor() {
    super();
    effect(
      () => {
        this.setClassToCustomElement('pt-1 pb-4');
      },
      { allowSignalWrites: true }
    );
  }
}
