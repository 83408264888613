<div class="container space-y-4">
  <div class="flex flex-col lg:flex-row items-start gap-8">
    <div class="inset-y-8 sticky h-fit space-y-4 rounded-md border border-input p-4">
      <span class="text-lg font-bold ml-2">Workspace</span>
      <ul class="flex lg:flex-col gap-2">
        @for (navItem of navItems; track navItem.label) {
          <li>
            <a
              hlmBtn
              size="sm"
              variant="ghost"
              class="justify-start gap-2 w-full"
              [routerLink]="navItem.route"
              routerLinkActive="text-accent-foreground bg-accent"
              [routerLinkActiveOptions]="{ exact: navItem.exact ?? false }"
              ariaCurrentWhenActive="page"
            >
              <hlm-icon size="normal" [name]="navItem.icon" />
              {{ navItem.label }}
            </a>
          </li>
        }
      </ul>
    </div>
    <div class="w-full">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
