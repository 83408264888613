/**
 * Hephaestus API
 * API documentation for the Hephaestus application server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: felixtj.dietrich@tum.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Message { 
    id: number;
    sentAt: string;
    sender: Message.SenderEnum;
    content: string;
    sessionId: number;
}
export namespace Message {
    export type SenderEnum = 'MENTOR' | 'USER';
    export const SenderEnum = {
        Mentor: 'MENTOR' as SenderEnum,
        User: 'USER' as SenderEnum
    };
}


