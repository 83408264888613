<div class="pt-4 flex flex-col gap-8">
  @if (query.error()) {
    <div class="flex items-center justify-center gap-2">
      <div hlmAlert class="max-w-xl" variant="destructive">
        <lucide-angular [img]="CircleX" hlmAlertIcon />
        <h4 hlmAlertTitle>Something went wrong...</h4>
        <p hlmAlertDesc>User couldn't be loaded. Please try again later.</p>
      </div>
    </div>
  } @else {
    @let showSkeleton = query.isPending();
    <app-user-header
      [user]="query.data()?.userInfo"
      [firstContribution]="query.data()?.firstContribution"
      [contributedRepositories]="query.data()?.contributedRepositories"
      [isLoading]="showSkeleton"
    />
    <div class="flex flex-col lg:flex-row gap-y-8 border-t border-border pt-6">
      <div class="flex flex-col flex-1 gap-4 ml-3">
        <h2 class="text-xl font-semibold">Latest Review Activity</h2>
        @let userActivity = showSkeleton ? skeletonReviews : query.data()?.reviewActivity;
        <hlm-scroll-area [autoHeightDisabled]="false" [style]="'height : ' + calcScrollHeight(userActivity)">
          <div class="flex flex-col gap-2 m-1 mr-3">
            @for (activity of userActivity; track activity.id) {
              <app-review-activity-card
                [isLoading]="showSkeleton"
                [state]="activity?.state"
                [submittedAt]="activity?.submittedAt"
                [htmlUrl]="activity?.htmlUrl"
                [pullRequest]="activity?.pullRequest"
                [repositoryName]="activity?.pullRequest?.repository?.name"
                [score]="activity?.score"
              />
            }
            @if (!showSkeleton && (!query.data()?.reviewActivity || query.data()?.reviewActivity?.length === 0)) {
              <div class="w-full h-20 flex justify-center items-center gap-4 border border-border bg-card rounded-lg p-4">
                <lucide-angular [img]="Info" class="text-muted-foreground" />
                <span class="text-muted-foreground font-normal">No activity found</span>
              </div>
            }
          </div>
        </hlm-scroll-area>
      </div>
      <div class="flex flex-col flex-1 gap-4 ml-3">
        <h2 class="text-xl font-semibold">Open Pull Requests</h2>
        @let userPullRequests = showSkeleton ? skeletonPullRequests : query.data()?.openPullRequests;
        <hlm-scroll-area [autoHeightDisabled]="false" [style]="'height : ' + calcScrollHeight(userPullRequests, 200)">
          <div class="flex flex-col gap-2 m-1 mr-3">
            @for (pullRequest of userPullRequests; track pullRequest.id) {
              <app-issue-card
                class="w-full"
                [isLoading]="showSkeleton"
                [additions]="pullRequest.additions"
                [deletions]="pullRequest.deletions"
                [number]="pullRequest.number"
                [repositoryName]="pullRequest.repository?.name"
                [title]="pullRequest.title"
                [htmlUrl]="pullRequest.htmlUrl"
                [state]="pullRequest.state"
                [isDraft]="pullRequest.isDraft"
                [isMerged]="pullRequest.isMerged"
                [createdAt]="pullRequest.createdAt"
                [pullRequestLabels]="pullRequest.labels"
              />
            }

            @if (!showSkeleton && (!query.data()?.openPullRequests || query.data()?.openPullRequests?.length === 0)) {
              <div class="w-full h-20 flex justify-center items-center gap-4 border border-border bg-card rounded-lg p-4">
                <lucide-angular [img]="Info" class="text-muted-foreground" />
                <span class="text-muted-foreground font-normal">No open pull requests found</span>
              </div>
            }
          </div>
        </hlm-scroll-area>
      </div>
    </div>
  }
</div>
