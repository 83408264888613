/**
 * Hephaestus API
 * API documentation for the Hephaestus application server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: felixtj.dietrich@tum.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LabelInfo } from './label-info';
import { RepositoryInfo } from './repository-info';
import { UserInfo } from './user-info';


export interface PullRequestInfo { 
    id: number;
    number: number;
    title: string;
    state: PullRequestInfo.StateEnum;
    isDraft: boolean;
    isMerged: boolean;
    commentsCount: number;
    author?: UserInfo;
    labels?: Array<LabelInfo>;
    assignees?: Array<UserInfo>;
    repository?: RepositoryInfo;
    additions: number;
    deletions: number;
    mergedAt?: string;
    closedAt?: string;
    htmlUrl: string;
    createdAt?: string;
    updatedAt?: string;
}
export namespace PullRequestInfo {
    export type StateEnum = 'OPEN' | 'CLOSED';
    export const StateEnum = {
        Open: 'OPEN' as StateEnum,
        Closed: 'CLOSED' as StateEnum
    };
}


