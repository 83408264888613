/**
 * Hephaestus API
 * API documentation for the Hephaestus application server.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: felixtj.dietrich@tum.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PullRequestBaseInfo } from './pull-request-base-info';
import { UserInfo } from './user-info';


export interface PullRequestReviewInfo { 
    id: number;
    isDismissed: boolean;
    state: PullRequestReviewInfo.StateEnum;
    codeComments: number;
    author?: UserInfo;
    pullRequest?: PullRequestBaseInfo;
    htmlUrl: string;
    score: number;
    submittedAt?: string;
}
export namespace PullRequestReviewInfo {
    export type StateEnum = 'COMMENTED' | 'APPROVED' | 'CHANGES_REQUESTED' | 'UNKNOWN';
    export const StateEnum = {
        Commented: 'COMMENTED' as StateEnum,
        Approved: 'APPROVED' as StateEnum,
        ChangesRequested: 'CHANGES_REQUESTED' as StateEnum,
        Unknown: 'UNKNOWN' as StateEnum
    };
}


