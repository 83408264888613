<a hlmCard variant="profile" [class]="computedClass()" [href]="htmlUrl()" target="_blank" rel="noopener noreferrer">
  <div hlmCardContent variant="profile">
    <div class="flex justify-between items-center text-sm text-github-muted-foreground">
      <span class="font-medium flex justify-center items-center space-x-1">
        @if (isLoading()) {
          <hlm-skeleton class="size-5 bg-green-500/30"></hlm-skeleton>
          <hlm-skeleton class="h-4 w-16 lg:w-36"></hlm-skeleton>
        } @else {
          <ng-icon [svg]="issueIconAndColor().icon" size="18" [class]="'mr-1 ' + issueIconAndColor().color"></ng-icon>
          {{ repositoryName() }} #{{ number() }} on {{ displayCreated().format('MMM D') }}
        }
      </span>
      <span class="flex items-center space-x-2">
        @if (isLoading()) {
          <hlm-skeleton class="h-4 w-8 bg-green-500/30"></hlm-skeleton>
          <hlm-skeleton class="h-4 w-8 bg-destructive/20"></hlm-skeleton>
        } @else {
          <span class="text-github-success-foreground font-bold">+{{ additions() }}</span>
          <span class="text-github-danger-foreground font-bold">-{{ deletions() }}</span>
        }
      </span>
    </div>

    <span class="containerSize flex justify-between font-medium mb-3">
      @if (isLoading()) {
        <hlm-skeleton class="h-6 w-3/4"></hlm-skeleton>
      } @else {
        <div [innerHTML]="displayTitle()" class="truncate"></div>
      }
    </span>
  </div>
  @if (!isLoading()) {
    <div hlmCardFooter class="flex flex-wrap gap-2 p-0 space-x-0">
      @for (label of pullRequestLabels(); track label.name) {
        <app-github-label [label]="label"></app-github-label>
      }
    </div>
  }
</a>
