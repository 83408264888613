@if (isDevMode()) {
  <angular-query-devtools />
}
<div class="flex flex-col min-h-dvh">
  <app-header />
  <main class="container flex-grow pt-4 pb-8">
    <router-outlet />
  </main>
  <app-footer />
</div>
