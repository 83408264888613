import { Directive, computed, input } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { type VariantProps, cva } from 'class-variance-authority';
import type { ClassValue } from 'clsx';

export const cardHeaderVariants = cva('flex p-6', {
  variants: {
    direction: {
      row: 'flex-row items-center space-x-1.5',
      column: 'flex-col space-y-1.5'
    }
  },
  defaultVariants: {
    direction: 'column'
  }
});
export type CardHeaderVariants = VariantProps<typeof cardHeaderVariants>;

@Directive({
  selector: '[hlmCardHeader]',
  standalone: true,
  host: {
    '[class]': '_computedClass()'
  }
})
export class HlmCardHeaderDirective {
  public readonly userClass = input<ClassValue>('', { alias: 'class' });
  protected _computedClass = computed(() => hlm(cardHeaderVariants({ direction: this.direction() }), this.userClass()));

  public readonly direction = input<CardHeaderVariants['direction']>('column');
}
