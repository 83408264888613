<hlm-tooltip>
  <a
    hlmBtn
    hlmTooltipTrigger
    hlmTooltipContentClass="bg-cyan-50 dark:bg-cyan-800 dark:border-cyan-500 border-cyan-300 text-cyan-500 dark:text-cyan-200"
    aria-describedby="AI Mentor"
    class="border-cyan-500 cursor-pointer text-cyan-500 hover:text-cyan-500 hover:bg-cyan-500/10 dark:hover:bg-cyan-500/20 gap-2"
    variant="outline"
    [size]="iconOnly() ? 'icon' : 'default'"
    [routerLink]="['/', 'mentor']"
    routerLinkActive="active"
  >
    <lucide-angular [img]="BotMessageSquare" class="size-4" />
    @if (!iconOnly()) {
      AI&nbsp;Mentor
    }
  </a>
  @if (iconOnly()) {
    <span *brnTooltipContent class="flex items-center">AI&nbsp;Mentor</span>
  }
</hlm-tooltip>
