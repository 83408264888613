<div class="flex flex-col mt-1.5">
  <div class="flex gap-2 items-center indent-2">
    <label hlmLabel class="">Select week</label>
    <hlm-tooltip>
      <button hlmTooltipTrigger size="icon" variant="outline" aria-describedby="Timeframe Info" class="leading-4">
        <hlm-icon size="sm" name="lucideHelpCircle" class="text-muted-foreground" />
      </button>
      <span *brnTooltipContent>Week starts {{ leaderboardSchedule().formatted }}</span>
    </hlm-tooltip>
  </div>
  <hlm-select [placeholder]="placeholder()" [(ngModel)]="selectValue" name="value">
    <hlm-select-trigger class="w-56">
      <hlm-select-value />
    </hlm-select-trigger>
    <hlm-select-content class="w-56">
      @for (option of options(); track option.id) {
        <hlm-option [value]="option.value">{{ option.label }}</hlm-option>
      }
    </hlm-select-content>
  </hlm-select>
  <span class="text-xs text-muted-foreground indent-4 mt-0.5"> {{ formattedDates() }}</span>
</div>
