<footer class="py-6 md:px-8 md:py-0 border-t">
  <div class="container flex flex-col items-center justify-between gap-2 md:gap-4 md:h-24 md:flex-row">
    <p class="text-balance text-center text-sm leading-loose text-muted-foreground md:text-left">
      <a routerLink="about" class="font-medium underline underline-offset-4">About</a>
    </p>
    <p class="text-balance text-center text-sm leading-loose text-muted-foreground md:text-left">
      <a routerLink="privacy" class="font-medium underline underline-offset-4">Privacy</a>
    </p>
    <p class="text-balance text-center text-sm leading-loose text-muted-foreground md:text-left">
      <a routerLink="imprint" class="font-medium underline underline-offset-4">Imprint</a>
    </p>
    <p class="text-balance text-center text-sm leading-loose text-muted-foreground md:text-left">
      <a data-canny-link href="https://hephaestus.canny.io/feature-requests" class="font-medium underline underline-offset-4">Feature requests</a>
    </p>
    <p class="text-balance text-center text-sm leading-loose text-muted-foreground md:text-right flex-1">
      Built by
      <a href="https://github.com/ls1intum" target="_blank" rel="noreferrer" class="font-medium underline underline-offset-4">LS1 Team</a>
      at
      <a href="https://www.tum.de/en/" target="_blank" rel="noreferrer" class="font-medium underline underline-offset-4">TUM</a>. The source code is available on
      <a href="https://github.com/ls1intum/hephaestus" target="_blank" rel="noreferrer" class="font-medium underline underline-offset-4"> GitHub</a>.
    </p>
  </div>
</footer>
