<div class="flex items-start space-x-3">
  <textarea hlmInput [(ngModel)]="message" placeholder="Message AI Mentor" style="resize: none" (keydown.enter)="onSendMessage()" class="w-full min-h-20"></textarea>
  <button
    hlmBtn
    hlmTooltipTrigger
    hlmTooltipContentClass="bg-cyan-500 text-white"
    [disabled]="isSending()"
    aria-describedby="Send Message"
    (click)="onSendMessage()"
    variant="default"
    size="icon"
  >
    @if (isSending()) {
      <hlm-spinner size="sm" />
    } @else {
      <lucide-angular [img]="Send" class="size-5" />
    }
  </button>
</div>
