<app-table>
  <thead appTableHeader>
    <tr appTableRow>
      <th appTableHead class="text-center">Rank</th>
      <th appTableHead>Contributor</th>
      <th appTableHead class="flex items-center gap-1 text-github-done-foreground">
        <hlm-icon size="sm" name="lucideAward" />
        Score
      </th>
      <th appTableHead>Activity</th>
    </tr>
  </thead>
  <tbody appTableBody>
    @if (isLoading()) {
      @for (entry of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; track entry; let idx = $index) {
        <tr appTableRow id="skeleton">
          <td appTableCell class="flex justify-center">
            <hlm-skeleton class="h-5 w-7" [style.width.px]="20 + 1 * idx" />
          </td>
          <td appTableCell class="py-2">
            <span class="flex items-center gap-2">
              <hlm-skeleton class="w-10 h-10 rounded-full" />
              <hlm-skeleton class="h-5" [style.width.px]="100 + (idx % 3) * 75" />
            </span>
          </td>
          <td appTableCell class="flex justify-center">
            <hlm-skeleton class="h-5" [style.width.px]="20 + (10 - idx) + (idx % 3) * 4" />
          </td>
          <td appTableCell class="py-2">
            <hlm-skeleton class="h-5" [style.width.px]="30 + ((idx % 4) * 20) / idx" />
          </td>
        </tr>
      }
    } @else if (!leaderboard() || leaderboard()?.length === 0) {
      <tr appTableRow>
        <td appTableCell colspan="4">
          <div class="flex flex-col items-center justify-center gap-2 mt-1">
            <ng-icon [svg]="octNoEntry" size="32" class="text-github-danger-foreground" />
            <span class="font-semibold text-muted-foreground">No entries found</span>
          </div>
        </td>
      </tr>
    } @else {
      @for (entry of leaderboard(); track entry.user.login) {
        <tr appTableRow routerLink="/user/{{ entry.user.login }}" routerLinkActive="active" ariaCurrentWhenActive="page" [class]="trClass(entry)">
          <td appTableCell class="text-center">{{ entry.rank }}</td>
          <td appTableCell class="py-2">
            <span class="flex items-center gap-2 font-medium">
              <hlm-avatar>
                <img [src]="entry.user.avatarUrl" [alt]="entry.user.name + '\'s avatar'" hlmAvatarImage />
                <span hlmAvatarFallback>
                  {{ entry.user.name.slice(0, 2).toUpperCase() }}
                </span>
              </hlm-avatar>
              <span class="text-muted-foreground">{{ entry.user.name }}</span>
            </span>
          </td>
          <td appTableCell class="text-center">{{ entry.score }}</td>
          <td appTableCell class="py-2">
            <div class="flex items-center gap-2">
              @if (entry.numberOfReviewedPRs) {
                <app-reviews-popover [reviewedPRs]="entry.reviewedPullRequests" [highlight]="this.user()?.username?.toLowerCase() === entry.user.login.toLowerCase()" />
                <div class="flex items-center text-github-muted-foreground">
                  <ng-icon [svg]="octChevronLeft" size="16" />
                </div>
              }
              @if (entry.numberOfChangeRequests > 0) {
                <div class="flex items-center gap-1 text-github-danger-foreground" title="Changes Requested">
                  <ng-icon [svg]="octFileDiff" size="16" />
                  {{ entry.numberOfChangeRequests }}
                </div>
              }
              @if (entry.numberOfApprovals > 0) {
                <div class="flex items-center gap-1 text-github-success-foreground" title="Approvals">
                  <ng-icon [svg]="octCheck" size="16" />
                  {{ entry.numberOfApprovals }}
                </div>
              }
              @if (entry.numberOfComments + entry.numberOfUnknowns > 0) {
                <div class="flex items-center gap-1 text-github-muted-foreground" title="Comments">
                  <ng-icon [svg]="octComment" size="16" />
                  {{ entry.numberOfComments + entry.numberOfUnknowns }}
                </div>
              }
              @if (entry.numberOfCodeComments > 0) {
                <div class="flex items-center gap-1 text-github-muted-foreground" title="Code comments">
                  <ng-icon [svg]="octCommentDiscussion" size="16" />
                  {{ entry.numberOfCodeComments }}
                </div>
              }
            </div>
          </td>
        </tr>
      }
    }
  </tbody>
</app-table>
