<div hlmCard>
  <div hlmCardContent class="flex flex-col gap-y-4 pb-0">
    <div class="flex flex-col gap-2 sm:min-w-[250px]">
      <h4 class="mb-2 text-lg font-semibold leading-none tracking-tight">Icons</h4>
      <div class="flex items-center gap-2 text-github-muted-foreground">
        <ng-icon [svg]="octGitPullRequest" size="16" />
        Reviewed pull requests
      </div>
      <div class="flex items-center gap-2 text-github-danger-foreground">
        <ng-icon [svg]="octFileDiff" size="16" />
        Changes requested
      </div>
      <div class="flex items-center gap-2 text-github-success-foreground">
        <ng-icon [svg]="octCheck" size="16" />
        Approvals
      </div>
      <div class="flex items-center gap-2 text-github-muted-foreground">
        <ng-icon [svg]="octComment" size="16" />
        Comments
      </div>
      <div class="flex items-center gap-2 text-github-muted-foreground">
        <ng-icon [svg]="octCommentDiscussion" size="16" />
        Code comments
      </div>
      <div hlmAccordion>
        <div hlmAccordionItem class="border-none">
          <button hlmAccordionTrigger>
            Scoring system
            <hlm-icon hlmAccIcon />
          </button>
          <hlm-accordion-content class="max-w-xl">
            <p class="text-sm text-github-muted-foreground">
              The score approximates your contribution activity by evaluating your review interactions and the complexity of the pull requests you've reviewed.
              <span class="font-semibold">Change requests are valued highest</span>, followed by approvals and comments. The score increases with the number of review interactions.
              Pull request complexity &mdash; based on factors like changed files, commits, additions, and deletions &mdash; also enhances your score;
              <span class="font-semibold">more complex pull requests contribute more</span>. The final score balances your interactions with the complexity of the work reviewed,
              highlighting both your engagement and the difficulty of the tasks you've undertaken. This score reflects your impact but does not directly measure time invested or
              work quality.
            </p>
          </hlm-accordion-content>
        </div>
      </div>
    </div>
  </div>
</div>
